import { TextField, Button, Box, Container } from "@mui/material";
import { useState } from "react"
import { useTranslation } from "react-i18next";
import { getService } from "../../app/api";
import { AuthService } from "../../api/services";

export default function Settings(){
  return (
    <div>
        <DeviceCodeAuthorize />
    </div>
  )
}

function DeviceCodeAuthorize(){
  const { t, i18n } = useTranslation();   
  let [code, setCode] = useState("")

  const onAuthorizeCode = async()=>{
    await getService(AuthService).authorizeDevice({code});
  }

  return (
    <Container>
      <Box>
        <TextField
              style={{fontSize:'large'}}
              label={t("Device Code")}             
              variant="outlined"
              aria-aria-readonly={true}
              onChange={(e)=>setCode(e.target.value)}
              value={code} />
      </Box>
      <Box style={{marginTop:'1rem'}}>         
        <Button variant="outlined" onClick={onAuthorizeCode} > {t('Authorize Code')} </Button>
      </Box>
    </Container>
  )
}