import React, { useCallback, useEffect } from "react";
import { MediaDto, MediaType } from "../../../api/models"
import styles from "./MediaView.module.css"
import VideoPlayer from "../VideoPlayer";

interface MediaViewProps{
    media: MediaDto;
    token:string|null;
    current: boolean
    onVideoPlay?: ()=>void;
    onVideoEnded?: ()=>void;
}

export default function MediaView({ media, token, current, onVideoPlay, onVideoEnded } : MediaViewProps){
    let [lod, setLod] = React.useState(1);
    let imageId = media.id;

    useEffect(()=>{
        if(current && media && media.mediaType == MediaType.image){
            setLod(0);   
        }
    },[current]);

    function onPlay(){
        if(onVideoPlay) onVideoPlay();
    }

    function onEnded(){
        if(onVideoEnded) onVideoEnded();
    }

    return (
        <div className={styles.root}>
            <div className={styles.media_frame}>
            {media && media.mediaType == MediaType.image && ( 
                <img src={`${process.env.REACT_APP_API_URL}/api/v1/media/${imageId}/content?lodLevel=${lod}&jwt=${token}`} data-current={current} />
            )}
            {current && media && media.mediaType == MediaType.video && (
                // <video
                //     src={`${process.env.REACT_APP_API_URL}/api/v1/media/${imageId}/content/stream?jwt=${token}`}
                //     poster={`${process.env.REACT_APP_API_URL}/api/v1/media/${imageId}/content/poster?jwt=${token}`}
                //     controls
                //     preload="auto"
                //     onPlay={onPlay}
                //     onEnded={onEnded}
                //     autoPlay
                // />
                
                <VideoPlayer item={media} 
                    token={token} 
                    muted={false} 
                    autoplay 
                    controls
                    onVideoPlay={onPlay}
                    onVideoEnded={onEnded} />
            )}
            {!current && media && media.mediaType == MediaType.video && (
                <img src={`${process.env.REACT_APP_API_URL}/api/v1/media/${imageId}/content/poster?jwt=${token}`} />
            )}
            </div>
        </div>
    )
}