import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { AlbumDto } from '../../api/models';
import { AlbumsService } from '../../api/services';
import { AsyncStatus, getService } from '../../app/api';
import { selectAppState } from '../../app/appSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { RootState, AppThunk } from '../../app/store';
import {actionLogout} from '../../app/appSlice';
import session from '../../app/Session';

export interface DrawerState {
    open: boolean    
}

const initialState: DrawerState = {
    open: false    
};

export const drawerSlice = createSlice({
    name: 'drawer',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        open: (state, action: PayloadAction<boolean>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.open = action.payload;
        }
    }
});


export const { open } = drawerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDrawer = (state: RootState) => state.drawer;
export default drawerSlice.reducer;

export function useAppDrawer() {    
    let state = useAppSelector(selectDrawer)
    const dispatch = useAppDispatch();
            
    return {        
        state,
        dispatch
    }
}
