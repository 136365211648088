import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { AlbumDto } from '../../api/models';
import { AlbumsService } from '../../api/services';
import { AsyncStatus, getService } from '../../app/api';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import { actionLogout } from '../../app/appSlice';
import session from '../../app/Session';

export interface AlbumsState {    
    albums: AlbumDto[],
    selectedAlbumId?: string,
    invalidated: boolean,
    status: AsyncStatus
}

export const fetchAsync = createAsyncThunk(
    'albums/fetch',
    async () => {
        let srv = getService(AlbumsService);
        const response = await srv.list({ page: 1, pageSize: 100 });        
        return response;
    }
);


export const albumsSlice = createSlice({
    name: 'drawer',
    initialState: {    
        invalidated: true,
        albums: [],
        status: AsyncStatus.idle
    } as AlbumsState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {     
        invalidate: (state, action: PayloadAction<boolean>) => {
            state.invalidated = action.payload;
        },

        setSelectedAlbumId(state, action) {
            state.selectedAlbumId = action.payload;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(actionLogout, (state)=>{                
                state.albums = [];
                state.selectedAlbumId = undefined;
                state.invalidated = true;
            })
            .addCase(fetchAsync.pending, (state) => {
                state.status = AsyncStatus.pending;
            })
            .addCase(fetchAsync.fulfilled, (state, action) => {
                state.status = AsyncStatus.idle;
                state.albums = action.payload.items || [];
                state.invalidated = false;
            })
            .addCase(fetchAsync.rejected, (state) => {
                state.status = AsyncStatus.failed;
                state.invalidated = false;
            });
    },
});


export const {  invalidate, setSelectedAlbumId } = albumsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAlbums = (state: RootState) => state.albums;
export default albumsSlice.reducer;

export function useAlbums() {    
    let state = useAppSelector(selectAlbums)
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (state.invalidated) {
            dispatch(fetchAsync());
        }
    }, [state.invalidated])

    const onLogin = (args: any)=>{
        dispatch(actionLogout());
    }

    useEffect(()=>{
        session.events.on('login', onLogin);
        return ()=> { 
            session.events.off('login', onLogin);
        }
    },[]);

    return {        
        state,
        dispatch
    }
}
