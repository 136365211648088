import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { useEffect } from 'react';
import { act } from 'react-dom/test-utils';
import { useParams } from 'react-router-dom';
import { AlbumDto, MediaDto } from '../../api/models';
import { AlbumsService, MediaService } from '../../api/services';
import { AsyncStatus, getService } from '../../app/api';
import { selectAppState } from '../../app/appSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import { selectAlbums } from '../album/ListAlbumSlice';


export interface MediaState {
    invalidated: boolean,
    status: AsyncStatus,
    items: MediaDto[];
    page: number;
    pageSize: number;
    totalPages: number;
    totalItems: number;
    showModal: boolean;
    showImage: boolean;
    selectedId?: string;
    albumId: string|null;
    album?:AlbumDto;
    selectedIndex?: number;
    onload?: 'show-first' | 'show-last';
}

const initialState: MediaState = {
    items: [],
    status: AsyncStatus.idle,
    page: 1,
    pageSize: 100,
    invalidated: false,
    totalPages: 0,
    totalItems: 0,
    showModal: false,
    showImage: false,
    albumId: null
};

export const fetchAsync = createAsyncThunk(
    'media/fetch',
    async (args: { albumId: string|null, page: number, pageSize: number }, thunkAPI) => {
        let srv = getService(MediaService);
        return await srv.list({ page: args.page, pageSize: args.pageSize, albumId: args.albumId });                
    }
);


export const mediaSlice = createSlice({
    name: 'media',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        invalidate: (state, action: PayloadAction<boolean>) => {
            state.invalidated = action.payload;
        },
        showModal(state, action) {
            state.showModal = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
            state.invalidated = true;
        },
        showImage(state, action) {
            state.showImage = action.payload.show;
            state.selectedId = action.payload.id;
            state.selectedIndex = action.payload.selectedIndex;
        },
        selectIndex(state, action) {
            state.selectedIndex = action.payload.selectedIndex;
            state.selectedId = state.items[state.selectedIndex || 0].id!;
        },
        setAlbumId(state, action: { payload: { albumId: string | null } }) {
            if (action.payload.albumId == null || state.albumId != action.payload.albumId) {
                state.albumId = action.payload.albumId;
                state.page = 1;
                state.items = [];
                state.totalPages = 0
                state.totalItems = 0;
                state.selectedId = undefined;
                state.showModal = false;
                state.showImage = false;
                state.status = AsyncStatus.pending;
                state.invalidated = true;
            }
        },
        setOnLoad(state, action) {
            state.onload = action.payload;
        }

    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder            
            .addCase(fetchAsync.pending, (state) => {
                state.status = AsyncStatus.pending
            })
            .addCase(fetchAsync.fulfilled, (state, action) => {
                state.status = AsyncStatus.idle;
                state.items = action.payload.items || [];
                state.page = action.payload.page;
                state.totalPages = action.payload.totalPages
                state.totalItems = action.payload.totalItems;
                state.invalidated = false;

                switch (state.onload) {
                    case 'show-first':
                        state.selectedIndex = 0;
                        state.selectedId = state.items[0].id!;
                        break;
                    case 'show-last':
                        state.selectedIndex = state.items.length - 1;
                        state.selectedId = state.items[state.items.length - 1].id!;
                        break;
                }

                state.onload = undefined;
            })
            .addCase(fetchAsync.rejected, (state) => {
                state.status = AsyncStatus.failed;
                state.invalidated = false;
            });
    },
});


export const { 
    invalidate, 
    showModal, 
    showImage, 
    selectIndex, 
    setOnLoad ,
    setAlbumId, 
    setPage } = mediaSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectMedia = (state: RootState) => state.media;
export default mediaSlice.reducer;

export function useAppMedia() {
    const param = useParams();
    const appState = useAppSelector(selectAppState);        
    const dispatch = useAppDispatch();
    
    const state = useAppSelector(selectMedia)

    const onPageChange = (page: number) => {
        dispatch(mediaSlice.actions.setPage(page));
    }

    const invalidate = (value: boolean) => {
        dispatch(mediaSlice.actions.invalidate(value));
    }    

    useEffect(() => {
        if (state.invalidated) {
            dispatch(fetchAsync({ albumId: param.id || null, page: state.page, pageSize: state.pageSize }));
        }
    }, [state.invalidated])

    return {
        appState,
        state,
        dispatch,        
        onPageChange,
        invalidate
    }
}
