import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, Button, Typography } from '@mui/material';
import { open, useAppDrawer } from './drawerSlice';
import {setSelectedAlbumId} from "../album/ListAlbumSlice";
import { selectAppState, showAlbumModal, setSectionName } from '../../app/appSlice';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from "react-router-dom";
import * as Media from '../media/mediaSlice'
import session from '../../app/Session';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { useTranslation } from 'react-i18next';
import styles from "./Drawer.module.css";
import AddIcon from '@mui/icons-material/Add';
import { useAppSelector } from '../../app/hooks';

const drawerWidth = 240;

export default function AppDrawer() {
    const { t, i18n } = useTranslation();
    let { state, dispatch } = useAppDrawer();    
    let navigate = useNavigate();

    function handleSelection(albumId: string) {
        dispatch(open(false))
        dispatch(setSelectedAlbumId(albumId));
        dispatch(Media.invalidate(true));
        navigate(`/album/${albumId}`, { replace: true });
    }

    return (
        <Drawer
            anchor='left'
            open={state.open}
            onClose={(e) => dispatch(open(false))}
            keepMounted={true}                        
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box'},
            }}> 
            <Toolbar className={styles.toolbar}>
                {session.canCreateAlbum() && (
                    <Button onClick={() => {
                        dispatch(open(false))
                        dispatch(showAlbumModal(true))
                    }}>
                    <div style={{display:'flex'}}><AddIcon style={{marginRight:'0.5rem'}}/><span>{t('menu.new-album')}</span> </div>
                </Button>)}                
            </Toolbar>
            <Divider />
            <Box sx={{ overflow: 'auto' }} >                                    
                <List>
                    <ListItem key={'galery'} disablePadding onClick={e => {
                        dispatch(open(false))
                        dispatch(setSelectedAlbumId(undefined));
                        dispatch(setSectionName('title.galery'))
                        navigate(`/`, { replace: true });
                    }}>
                     <ListItemButton >
                            <ListItemIcon >
                                <HomeIcon color='secondary' />
                            </ListItemIcon>
                            <ListItemText primary={ <Typography color='secondary' >{t('title.galery')}</Typography> }  />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={'albums'} disablePadding onClick={e => {
                        dispatch(open(false))
                        dispatch(setSelectedAlbumId(undefined));
                        dispatch(setSectionName('title.albums'))
                        navigate(`/albums`, { replace: true });
                    }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <PhotoLibraryIcon color='secondary' />
                            </ListItemIcon>
                            <ListItemText  primary={ <Typography color='secondary' >{t('title.albums')}</Typography>} />
                        </ListItemButton>
                    </ListItem>
                    {/* <Divider />
                    {state.albums.map((item, index) => (
                        <Box key={item.id} className={state.selectedAlbumId == item.id ? 'selected-album' : undefined}>
                            <ListItem key={item.id} disablePadding onClick={e => handleSelection(item.id!)}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {item.coverId ?
                                            <Avatar src={`${process.env.REACT_APP_API_URL}/api/v1/media/${item.coverId!}/content?lodLevel=2`} />
                                            : <img src="/album48.png" width="32" height="32" />
                                        }
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} />
                                </ListItemButton>
                            </ListItem>
                            <Divider variant="middle" component="li" />
                        </Box>
                    ))} */}
                </List>
            </Box>
        </Drawer >

    );
}
