import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import drawerSlice from '../features/drawer/drawerSlice';
import albumSlice from '../features/album/ListAlbumSlice';
import mediaSlice from '../features/media/mediaSlice';
import appSlice from './appSlice';

export const store = configureStore({
  reducer: {
    albums: albumSlice,
    drawer: drawerSlice,
    app: appSlice,
    media: mediaSlice
  }
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
