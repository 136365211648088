import { env } from 'process';
import { AuthService } from '../api/services';

export enum AsyncStatus {
    idle, pending, failed
}

type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>;
type ServiceOptions = { baseUrl: string; };

export interface ServiceConstructor<T> {
    new(fetch: Fetch, options: ServiceOptions): T;
}

export function getService<T>(srvCtor: ServiceConstructor<T>): T {
    return new srvCtor(fetchIntercep, { baseUrl: process.env.REACT_APP_API_URL });
}

export async function fetchIntercep(input: RequestInfo, options?: RequestInit): Promise<Response> {
    if (options && options.headers && (options.headers as Record<string, string>)['Authorization'] === 'Bearer') {
        let token = localStorage.getItem('token');
        if (!token) {
            window.location.assign("/login");
            throw new Error("Login Required");
        }

        (options.headers as any)['Authorization'] = `Bearer ${token}`;
    }

    try {
        let retry = 0;
        while (retry < 2) {
            let response = await window.fetch(input, options);
            if (response.status == 401) {
                if (options) {
                    (options.headers as any)['Authorization'] = 'Bearer';
                }

                localStorage.removeItem('token');

                if (localStorage.getItem('refresh-token') && ((typeof input == 'string') && !input.endsWith('/api/Auth/token')) && options) {
                    let token = await updateToken(localStorage.getItem('refresh-token')!);
                    (options.headers as any)['Authorization'] = `Bearer ${token}`;
                    retry++;
                    continue;
                } else {
                    window.location.assign("/login");
                    throw new Error("Login Required");
                }
            }
            return response;
        }
        throw new Error('API Error')
    } catch (e) {
        console.error(e);
        throw e;
    }
}

async function updateToken(refreshToken: string) {
    const srv = getService(AuthService);
    try {
        let reply = await srv.getToken({
            grandType: 'refresh_token',
            refreshToken: refreshToken
        });
        if (reply) {
            if (reply.access_token) {
                window.localStorage.setItem('token', reply.access_token);
            }
            if (reply.refresh_token) {
                window.localStorage.setItem('refresh-token', reply.refresh_token);
            }
        }

        return reply.access_token;
    } catch (e) {
        console.error(e);
        window.location.assign("/login");
        throw new Error("Login Error");
    }
}
