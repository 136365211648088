import { useEffect, useRef } from "react";
import { MediaDto } from "../../api/models";
import dashjs, { MediaPlayer , MediaPlayerEvents } from 'dashjs';

interface VidePlayerProps{
    item: MediaDto;
    token:string|null;
    muted: boolean,
    controls: boolean,
    autoplay: boolean,
    onVideoPlay?: ()=>void;
    onVideoEnded?: ()=>void;
}

export default function VideoPlayer(props: VidePlayerProps){
    let { item, token} = props;
    let videoRef = useRef<HTMLVideoElement>(null);
    let playerRef = useRef<dashjs.MediaPlayerClass|null>(null)
    
    let poster = `${process.env.REACT_APP_API_URL}/api/v1/media/${item.id}/content/poster?jwt=${token}`;

    useEffect(()=>{
        if(videoRef.current){
            if(item.isMpegDashVideo){
                let player = MediaPlayer().create();  
                playerRef.current = player;
                player.extend("RequestModifier", function () {
                    return {    
                        modifyRequestHeader: function (xhr:XMLHttpRequest, arg:any) {
                            /* Add custom header. Requires to set up Access-Control-Allow-Headers in your */
                            /* response header in the server side. Reference: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/setRequestHeader */
                            /* xhr.setRequestHeader('DASH-CUSTOM-HEADER', 'MyValue'); */
                            xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                            return xhr;
                        },                   
                        // modifyRequestURL: function (url: string) {   
                        //     if(url.endsWith('webm'))                      
                        //         return url + `?jwt=${token}`;
                        //     return url;
                        // }                      
                    };
                }, false);
                
                player.initialize();
                player.updateSettings({
                    debug: {
                        logLevel: 0  /* turns off console logging */
                    },
                    streaming: {
                        scheduling: {
                            scheduleWhilePaused: false,   /* stops the player from loading segments while paused */
                        },
                        buffer: {                            
                            fastSwitchEnabled: true   /* enables buffer replacement when switching bitrates for faster switching */                        
                        }
                    }
                });
               
                player.setAutoPlay(props.autoplay);                
                player.attachView(videoRef.current);
                player.attachSource(`${process.env.REACT_APP_API_URL}/api/v1/media/${item.id}/mpd`)       
                player.setMute(props.muted); 
                if(props.onVideoPlay){
                    player.on('playbackStarted', ()=> props.onVideoPlay!());        
                }
                if(props.onVideoEnded){
                    player.on("playbackEnded", ()=> [props.onVideoEnded!()])
                }
               

                return ()=> {
                    player?.destroy();                    
                    playerRef.current = null;
                } 
            }else{
                videoRef.current.src = `${process.env.REACT_APP_API_URL}/api/v1/media/${item.id}/content/stream?&jwt=${token}`;
            }
        }
    },[item.id, token, props.muted]);

    return (
        <video ref={videoRef}        
        poster={poster}                            
        preload={item.isMpegDashVideo ? "none" :"auto"}
        autoPlay = { !item.isMpegDashVideo && props.autoplay}
        muted = {props.muted}
        controls = {props.controls}
        onPlay={props.onVideoPlay}
        onEnded={props.onVideoEnded}
         />
    )
}