import { AppBar, Box, Button, Card, Grid, IconButton, Input, Paper, TextField, Toolbar, Typography } from "@mui/material";
import styles from "./EditAlbum.module.css";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { selectAppState, showAlbumModal } from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useRef, useState } from "react";
import { getService } from "../../app/api";
import { AlbumsService } from "../../api/services";
import { AlbumDto } from "../../api/models";
import { invalidate } from "./ListAlbumSlice";
import React from 'react';
import { useSnackbar } from 'notistack'
import { useTranslation } from "react-i18next";

interface FileState {
    file?: File;
    url?: string;
}

export default function EditAlbum() {
    const dispatch = useAppDispatch();
    const appState = useAppSelector(selectAppState);
    let [album, setAlbum] = useState<AlbumDto>({});
    let [loading, setLoading] = useState(false);
    let [fileState, setFileState] = useState<FileState>({});
    const imagePanelRef = useRef<HTMLDivElement | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const { t, i18n } = useTranslation();
    
    useEffect(() => {
        return () => {
            if (fileState.url) {
                URL.revokeObjectURL(fileState.url);
            }
        }
    }, [])


    function handleClose() {
        dispatch(showAlbumModal(false));
    }
    const handleSave = async () => {

        if (!album.name || album.name == '') {
            enqueueSnackbar('EL nombre del album es requerido', { variant: 'error' });
            return;
        }
        setLoading(true);
        const srv = getService(AlbumsService);

        try {
            let reply = await srv.create(album);

            dispatch(showAlbumModal(false));
            dispatch(invalidate(true));
            enqueueSnackbar(`Album ${album.name} creado`, { variant: 'success' });
        } catch (err) {
            enqueueSnackbar(`Error creando album ${album.name}`, { variant: 'error' });
        }
        finally {
            setLoading(false);
        }
    }

    function setValue(field: keyof AlbumDto, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setAlbum(prev => {
            return {
                ...prev,
                [field]: e.target.value
            } as AlbumDto
        });
    }

    function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (fileState.url) {
            URL.revokeObjectURL(fileState.url);
        }

        if (e.target.files) {
            let file = e.target.files[0];
            let url = URL.createObjectURL(file);
            setFileState({ url, file });
        } else {
            setFileState({ url: undefined, file: undefined });
        }
    }

    return (
        <Paper className={styles.root} >
            <AppBar color="primary" sx={{ position: 'relative' }} elevation={3} >
                <Toolbar variant="dense">
                    <Box sx={{ flexGrow: 1 }} >
                        <Typography >NUEVO ALBUM</Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Grid container spacing={1} >
                <Grid container item spacing={2} md={6} xs={12} alignContent="start" style={{width:'100%'}} >
                    <Grid item xs={12} justifyContent="center">
                        <LoadingButton
                            variant="outlined"
                            loading={loading}
                            loadingPosition="start"
                            fullWidth={true}
                            startIcon={<SaveIcon />}
                            onClick={handleSave}>
                            Guardar
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField required label="Name" fullWidth={true} onChange={e => setValue('name', e)} value={album.name} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Description" fullWidth={true} onChange={e => setValue('description', e)} value={album.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Tags" fullWidth={true} onChange={e => setValue('tags', e)} value={album.tags} />
                    </Grid>
                </Grid>
                {/* <Grid container item md={6} xs={12} className={styles.image} justifyContent="center" >
                    <Button
                        variant="contained"
                        component="label"
                        fullWidth={true}>
                        Cargar Imagen de Miniatura
                        <input type="file" hidden onChange={onFileChange} />
                    </Button>
                    <Paper elevation={3} ref={imagePanelRef} style={{ padding: 8, display: 'flex', justifyContent: 'center' }} >
                        {fileState.url && (<img src={fileState.url} width={(imagePanelRef.current?.clientWidth ?? 300) - 16} />)}
                    </Paper>
                </Grid> */}
            </Grid>
        </Paper >
    );
}