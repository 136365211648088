import { LoadingButton } from "@mui/lab";
import { Typography, AppBar, IconButton, Popover, Toolbar, Button, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import styles from "./MediaDetails.module.css";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import React, { useEffect } from "react";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { MediaService } from "../../api/services";
import { getService } from "../../app/api";
import { MediaDto } from "../../api/models";
import session from "../../app/Session";
import { SlideListView } from "../SlideListView/SlideListView";
import MediaView from "./MediaView/MediaView"
import { useTranslation } from "react-i18next";
import { clsx } from "clsx";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setPage } from "./mediaSlice"

type MediaCallback = (value: string) => void;

interface MediaDetailsProps {
    index: number;
    medias: MediaDto[];
    onClose: MediaCallback;
    onDeleted?: MediaCallback;
    onNavigate?: (index: number) => void;    
}

interface NavigationProps{
    selectedIndex: number
    itemsCount: number;
    totalPages: number;
    page:number;
    pageSize:number;
}

export default function MediaDetails(props: MediaDetailsProps) {    
    const { t, i18n } = useTranslation();         
    let [actionsStatus, setActionStatus] = React.useState({
        deleting: false,
        downloading: false,
        backNav: false
    });
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);    
    let [media, setMedia] = React.useState<MediaDto>(props.medias[props.index]);
    let [selectedIndex, setSelectedindex] = React.useState(props.index);
    let [medias, setMedias] = React.useState(props.medias);
    let [appBarState, setAppBarState] = React.useState({visible: true})
    let [slideshow, setSlideShow] = React.useState(false);    
    let token = session.getToken();
    let rootRef = React.useRef<HTMLDivElement|null>(null)
    let mediasState = useAppSelector(x=>x.media);        
    let dispatch = useAppDispatch();

    useEffect(()=>{
        if(anchorEl == null){
            let timeout  = setTimeout(()=>setAppBarState({visible: false}), 3000);
            return ()=> {
                clearTimeout(timeout);
            }    
        }
    },[props.index]);

    // begin events
    const openActions = (event: React.MouseEvent<HTMLButtonElement>) => {        
        setAnchorEl(anchorEl == null ? event.currentTarget: null);
        if(anchorEl !== null){
            appBarState.visible = false;
            setAppBarState({ visible: false });
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
        appBarState.visible = false;
        setAppBarState({ visible: false });
    };

    const deleteImage = async () => {
        if(!media) return

        handleClose();
        setActionStatus({ ...actionsStatus, deleting: true });
        try {
            const service = getService(MediaService);
            await service.delete({ id: media.id });
            setAnchorEl(null);
            if (props.onDeleted) props.onDeleted(media.id!);
        }
        finally {
            setActionStatus({ ...actionsStatus, deleting: false })
        }
    }

    const downloadImage = async () => {
        if(!media) return

        handleClose();
        setActionStatus({ ...actionsStatus, downloading: true });
        try {
            let response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/media/${media.id}/content?lodLevel=${0}&jwt=${token}`);
            if(response.status !== 200){
                throw new Error('failed to fecth');                                
            }
            let blob = await response.blob();
            if (blob) {
                let objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = objectUrl;
                a.target = '_blank';

                let contentDiposition = response.headers.get('Content-Disposition');
                if (contentDiposition) {
                    let name = contentDiposition.split(';').map(x => x.trim()).find(x => x.startsWith('filename='));
                    if (name) {
                        a.download = name?.split('=')[1];
                    }
                }

                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(objectUrl);
            }
        }
        finally {
            setActionStatus({ ...actionsStatus, downloading: false });
        }
    }

    const onMouseMove = (x:number, y:number)=>{
        if(!appBarState.visible && y < 60){
            showAppBar();
        }
    }
    const onPointerClick = (x:number, y:number)=>{
        if(!appBarState.visible){
            showAppBar();
        }
    }

    const onSlideShow = ()=>{        
        if(rootRef.current && !slideshow){
            rootRef.current.requestFullscreen({ navigationUI : "auto"})
            .then(()=>{
                setSlideShow(true);
                rootRef.current!.onfullscreenchange = ()=>{
                    if(document.fullscreenElement == null){
                        setSlideShow(false);    
                    }
                }
            });                        
        }else if(slideshow){
            document.exitFullscreen();
        }
        handleClose();
    }

    function showAppBar() {        
        appBarState.visible = true;
        setAppBarState({ visible: true });
        if(anchorEl == null){
            setTimeout(() => {
                appBarState.visible = false;
                setAppBarState({ visible: false });
            }, 3000);
        }
    }

    async function onSelectedIndexChanged(idx:number){
        setMedia(medias[idx]);
        setSelectedindex(idx);       
        let pageSize = mediasState.pageSize;
        let page = mediasState.page;
        
        let halfItems = Math.floor(medias.length / 2);
        if(idx === 0 && page > 1){   
            dispatch(setPage(page - 1));       

            let result = await getService(MediaService).list({
                albumId: mediasState.albumId,
                pageSize: page,
                page: page - 1
            });
            if(result.items && result.items.length > 0){
                medias = result.items.concat(medias.slice(0, halfItems));
                setMedias(medias)
                setSelectedindex(idx + result.items.length);                    
            }
        } else if(idx  === medias.length - 1){
            
            if(page < mediasState.totalPages){
                page = page + 1;                
            }else if(page == mediasState.totalPages){
                page = 1;
            }

            dispatch(setPage(page));
            let result = await getService(MediaService).list({
                albumId: mediasState.albumId,
                pageSize: pageSize,
                page: page                                
            });

            if(result.items && result.items.length > 0){
                medias = medias.slice(halfItems).concat(result.items);
                setMedias(medias);   
                setSelectedindex(idx - halfItems)                 
            }
        }            
    }

    // end events


    return (
        <div className={styles.root} ref={rootRef}>
            <AppBar sx={{ position: 'relative' }} className={ clsx(styles.appBar, appBarState.visible && styles.appBarvisible)}>
                <Toolbar variant="dense">
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={(e) => props.onClose(media.id!)}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {media?.name}
                    </Typography>
                    <div>
                        <IconButton size="small" style={{ color: 'white' }} onClick={openActions} >
                            <MoreVertIcon></MoreVertIcon>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <div className={styles.content}>
                <SlideListView            
                    slideshow = {slideshow}     
                    items={medias || [media]} 
                    selectedIndex={selectedIndex}
                    onSelectedIndexChanged={onSelectedIndexChanged}   
                    onPointerMove={onMouseMove} 
                    onPointerClick={onPointerClick}                    
                    render={ (media, current , idx)=> 
                       <MediaView  
                            media={media} 
                            token={token} 
                            current={current} 
                            onVideoPlay={()=> document.fullscreenElement && setSlideShow(false)}
                            onVideoEnded={()=> document.fullscreenElement && setSlideShow(true)}   /> } 
                    />  
            </div> 

             <div className={styles.actionContainer} 
                style={ anchorEl != null ? {display:'flex',  transition:'all 500ms ease-in'}: { bottom:'101%', transition:'all 500ms ease-out'}}
                    onMouseLeave={()=>handleClose()} >
                  {session.canWriteMedia(media) && (
                    <LoadingButton
                    variant="text"
                    loading={actionsStatus.deleting || false}
                    loadingPosition="start"
                    startIcon={<DeleteIcon />}
                    color="error"                    
                    onClick={deleteImage}
                >
                   {t('delete')}
                </LoadingButton>
                  )}
                    <LoadingButton
                        variant="text"
                        loading={actionsStatus.downloading || false}
                        loadingPosition="start"
                        startIcon={<CloudDownloadIcon />}
                        color="primary"                        
                        onClick={downloadImage}
                    >
                        {t('download')}
                    </LoadingButton>
                    <Button startIcon ={<SlideshowIcon/>} onClick={onSlideShow} >
                        {t("slideshow")}
                    </Button>
                </div>                              

            {/* <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', padding: '3px', alignItems: 'baseline' }}>
                  {session.canWriteMedia(media) && (
                    <LoadingButton
                    variant="text"
                    loading={actionsStatus.deleting || false}
                    loadingPosition="start"
                    startIcon={<DeleteIcon />}
                    color="error"
                    onClick={deleteImage}
                >
                   {t('delete')}
                </LoadingButton>
                  )}
                    <LoadingButton
                        variant="text"
                        loading={actionsStatus.downloading || false}
                        loadingPosition="start"
                        startIcon={<CloudDownloadIcon />}
                        color="primary"
                        onClick={downloadImage}
                    >
                        {t('download')}
                    </LoadingButton>
                    <Button startIcon ={<SlideshowIcon/>} onClick={onSlideShow} >
                        {t("slideshow")}
                    </Button>
                </div>
            </Popover> */}
        </div>
    )


}

export function ImageViwerWrapper() {
    let param = useParams();
    let [media, setMedia] = React.useState<MediaDto>();
    const { t, i18n } = useTranslation();
    
    let mediaId = param.id!;
    useEffect(() => {
        const service = getService(MediaService);
        if (media == null || media.id != mediaId) {
            service.get({ id: mediaId }).then(
                val => setMedia(val)
            );
        }
    }, [param.id]);
        
    if(media)
        return (<MediaDetails medias={[ media ]} index={0} onClose={() => { window.history.back() }} />)
    return (<div>{t('loading')}...</div>)
}

