import Box from "@mui/material/Box";
import { ReactNode, useMemo, useState } from "react";
import viewModel from "./profileSlice";
import session from '../../app/Session';
import { Paper, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import style from './profile.module.css'
import UserInfo from "./UserInfo";
import TenantInfo from "./TentantInfo";
import Settings from "./Setting";

export default function Profile(){
    const { t, i18n } = useTranslation();           
    const [tabIndex, setTabIndex] = useState(0);
    let userId = session.userId!;    
    var vm = useMemo(()=> viewModel(userId), [session.userId]);    
    if(session.userId == null) return null;

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setTabIndex(newValue);
    };

    return (  
    <Paper className={style.root}>      
      <Tabs onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tab label={t("User")}></Tab>
        <Tab label={t("Tenant")}></Tab>
        <Tab label={t("Settings")}></Tab>
      </Tabs>           
      <TabContent value={tabIndex} index={0}>
        <UserInfo />
      </TabContent>    
      <TabContent value={tabIndex} index={1}>
         <TenantInfo />
      </TabContent>   
      <TabContent value={tabIndex} index={2}>
          <Settings />
      </TabContent>  
    </Paper>);
}

function TabContent({index, value, children}:{index:number, value:number, children:ReactNode}){
  return(
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}      
      >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>);
}

