import { useAppDispatch } from "../../app/hooks";
import styles from "./Galery.module.css";
import { Box, CircularProgress, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { MediaDto } from "../../api/models";
import session from "../../app/Session";
import MediaCard from "../media/MediaCard";
import ImageDetails from "../media/MediaDetails";
import { getService } from "../../app/api";
import { MediaService } from "../../api/services";
import InfiniteScroll from "react-infinite-scroll-component";

interface HomeData{
    items: MediaDto[];    
    page: number;
    pageSize: number;
    totalPages: number;
    totalItems: number;              
}

export default function Home(){
    const dispatch = useAppDispatch();       
    const[ invalidated, setInvalidated] = useState(true);    
    const [ showImage , setShowImage] = useState<{show:boolean, id?:string, selectedIndex?:number}>({
        show: false, 
        id: undefined, 
        selectedIndex: undefined
    });
    const[ state , setState] = useState<HomeData>({
        items:[],
        page: 1,
        pageSize: 25,    
        totalPages: 0,
        totalItems: 0,
    });

    const [onLoad, setOnload] = useState<'show-first' | 'show-last'|undefined>();
    
    const token = session.getToken();

    function onView(imageId: string) {
        let selectedIndex = state.items.findIndex(x => x.id === imageId);
        setShowImage({ show: true, id: imageId, selectedIndex: selectedIndex });
    }

    const onDeleted = () => {
        setShowImage({ show: false, id: undefined });
        setInvalidated(true)
    }

    const onNavigate = (idx: number) => {
        if (idx >= state.items.length && state.page < state.totalPages) {
            setOnload('show-first')
            setState(prev=>({ ...prev,  page: state.page + 1}));            
        } else if (idx < 0 && state.page > 1) {
            setOnload('show-last')
            setState(prev=>({ ...prev,  page: state.page - 1}));            
        } else {
            setState(prev=>({ ...prev,  selectedIndex: idx }));            
        }
    }

    useEffect(() => {
        if(invalidated){
            let srv = getService(MediaService);
            srv.list({ page: state.page, pageSize: state.pageSize })
            .then(res=>{
                setState(prev=>(
                    {
                    ...prev,
                    items: res.items||[],
                    page : res.page,
                    totalItems: res.totalItems,
                    totalPages: res.totalPages
                }));
                setInvalidated(false);
                
            });
        }
    },[invalidated]);
    
    async function fetchMore(){
        if(state.page <= state.totalPages){
            let srv = getService(MediaService);
            let res= await srv.list({ page: state.page + 1, pageSize: state.pageSize });

            setState(prev=>(
                {
                ...prev,
                items: [...prev.items, ... res.items || []],
                page : res.page,
                totalItems: res.totalItems,
                totalPages: res.totalPages
            }));                                
        }
    }
    
    return (        
        <Box className={styles.root}>
              <div style={{ position: 'relative' }}>
                {invalidated === true && (
                    <div className="progress-indicator">
                        <CircularProgress size="5rem" />
                    </div>
                )}
                <div className={invalidated === true ? 'content-loading' : undefined}>                              
                        <InfiniteScroll 
                            className={styles.image_grid}
                            dataLength={state.items.length}
                            next ={fetchMore}
                            hasMore={state.page < state.totalPages}
                            loader={ <CircularProgress size="5rem" /> } 
                            endMessage={<p>No hay mas contenido</p>} >
                            {state.items.map(item => <MediaCard key={item.id} item={item} onView={onView} onDeleted={() => setInvalidated(true)} token={token} />)}
                        </InfiniteScroll>                        
                                    
                </div>
            </div>            

            <Dialog
                fullScreen={true}
                open={showImage.show}
                onClose={(e) => setShowImage({ show: false, id: undefined })}
            >
                {showImage?.id &&
                    <ImageDetails
                        index = {showImage.selectedIndex!}
                        medias= { state.items}
                        onClose={() => setShowImage({ show: false, id: undefined })}
                        onDeleted={onDeleted}
                        onNavigate={onNavigate}
                        />
                }
            </Dialog>
        </Box>
    )
}