import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import session from '../../app/Session';
import { createContext, useContext, useState } from "react";
import { UserDto } from "../../api/models";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from '@mui/icons-material/Save';
import { getService } from "../../app/api";
import { UserService } from "../../api/services";
import { useSnackbar } from "notistack";
import { FormModel, useFormModel, Validators } from "../../app/FormModel";
import { useAppDispatch } from "../../app/hooks";

// interface ValidationContext{
//     valid:boolean
//     errors?:{[key:string]:string},
// }
// interface ContextInfo {
//     data: any;
//     val:ValidationContext;
//     onChange: ChangeEventHandler<HTMLInputElement> 
// }

interface UserEditModel extends UserDto {
    password?: string;
    passwordRepeat?: string;
}

const UserContext = createContext<FormModel<UserEditModel>|null>(null);

export default function UserInfo(){    
    const { t, i18n } = useTranslation();      
    let [loading, setLoading] = useState(false);    
    const { enqueueSnackbar } = useSnackbar();
    const { form, model, validateForm } = useFormModel<UserEditModel>(session.user || {},
       {
            name: Validators.required, 
            firstLastName: Validators.required, 
            secondLastName: Validators.required,
            passwordRepeat: (v, m)=> v === m.password && v !== m.password ? 'password not match':null 
        });
    // const formModel:FormModel<UserEditModel> = {
    //     data: user,
    //     errors:{},
    //     onChange: e=>setUser( (u:any)=> ({...u, [e.target.name]: e.target.value})),
    //     val : {
    //         name: Validators.required, 
    //         firstLastName: Validators.required, 
    //         secondLastName: Validators.required,
    //         passwordRepeat: (v, m)=> v === m.password ? null:'password not match' 
    //     }
    // }    

    const handleSave = async () => {
        setLoading(true);         
        try {         
            if(!validateForm(form)){             
                enqueueSnackbar('Invalid Field', { variant: 'error' });
                return;                
            }

            const srv = getService(UserService);
           let user = await srv.update(form.data);        
           session.onUserChanged(user);            
            enqueueSnackbar(t('Success'), { variant: 'success' });
        } catch (err) {
            enqueueSnackbar('Error', { variant: 'error' });
        }
        finally {
            setLoading(false);
        }
    }
   
    return (
        <UserContext.Provider value={form}>
            <Grid container item spacing={2} md={6} xs={12} alignContent="start" style={{width:'100%'}} >
                <Grid item xs={12}>
                    <FormTextField field="name" label={t("Name")} errors={form.errors} />                    
                </Grid>
                <Grid item xs={12}>
                    <FormTextField  field="firstLastName" label={t("FirstLastName")} errors={form.errors} />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField field="secondLastName"  label={t("SecondLastName")} errors={form.errors} />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField field="role"  label={t("Role")} readonly />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField field="email"  label={t("Email")} readonly />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField field="password"  label={t("Password")} password errors={form.errors}/>
                </Grid>
                {model.password && (
                    <Grid item xs={12}>
                        <FormTextField field="passwordRepeat"  label={t("Repeat Password")} password errors={form.errors} />
                    </Grid>)
                }

                <Grid item xs={12} justifyContent="center">
                    <LoadingButton
                        variant="outlined"
                        loading={loading}
                        loadingPosition="start"
                        fullWidth={true}
                        startIcon={<SaveIcon />}
                        onClick={handleSave}>
                        Guardar
                    </LoadingButton>
                    </Grid>
            </Grid>
        </UserContext.Provider>
    )
}


function FormTextField(props:{field:string; label: string, readonly?:boolean, password?:boolean, errors?:{[key: string]:string}}){
    const form = useContext(UserContext);
    if(!form) return null;

    let value = (form.data as any)[props.field]
    return (
        <TextField name={props.field} 
            type={ props.password === true ? 'password' : 'text'}
            label={props.label}                       
            fullWidth={true}             
            value={value}             
            disabled={props.readonly}
            onChange={!props.readonly && form?.onChange || undefined}
            error={ props.errors && props.errors[props.field] != undefined }
            helperText={ props.errors && props.errors[props.field]} />
    )
}