import { AppBar, Box, Button, IconButton, LinearProgress, Paper, TextField, Toolbar, Typography } from "@mui/material";
import styles from "./UploadImage.module.css";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import * as React from "react";
import { getService } from "../../app/api";
import { AlbumsService, MediaService } from "../../api/services";
import { MediaDto, MediaType } from "../../api/models";
import { showModal, invalidate } from "./mediaSlice";
import { invalidate as invalidateAlbum } from "../album/ListAlbumSlice";
import { useParams } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { Album } from "@mui/icons-material";

interface UploadFileState {
    file: File;
    item: MediaDto;
    posterBlob?: Blob | null;
}


export default function UploadImage() {
    const dispatch = useAppDispatch();
    let [loading, setLoading] = React.useState(false);
    let [files, setFiles] = React.useState<UploadFileState[]>([]);
    let [uploadCount, setUploadCount] = React.useState(0);
    let [progress, setProgress] = React.useState(-1);
    let param = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const albumId = param.id;

    function handleClose() {
        dispatch(showModal(false));
    }

    function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files) {
            let newFiles = [...files];
            for (let file of e.target.files) {
                newFiles.push({
                    file,
                    item: {
                        name: file.name,
                        albumId: albumId,
                        mediaType: file.type.startsWith('image') ? MediaType.image : MediaType.video,
                        sizeInBytes: file.size,
                        contentType: file.type
                    }
                });
            }
            setFiles(newFiles);
        }
    }

    const onRemove = (file: UploadFileState) => {
        setFiles(files.filter(f => f !== file));
    }

    const handleSave = async () => {
        const srv = getService(MediaService);
        let uploads: UploadFileState[] = [];
        setLoading(true);

        let wakeLock = await (navigator as any).wakeLock?.request();
        try{            

            for (let f of files) {
                let reply: MediaDto | null = null;

                try {
                    reply = await srv.create(f.item);
                    if(!reply || !reply.id){
                        enqueueSnackbar(`Error subiendo ${f.item.name}`, { variant: 'error' });
                        console.log('error creating metadata');
                        continue;
                    }

                    if (f.posterBlob) {
                        await uploadStream(reply.id, f.posterBlob);
                        // await srv.uploadContent({
                        //     id: reply.id!,
                        //     content: f.posterBlob,
                        //     contentType: 'image/jpg'
                        // });
                    }

                    await uploadStream(reply.id, f.file);

                    uploadCount++;
                    setUploadCount(uploadCount);
                    uploads.push(f);
                    enqueueSnackbar(`${f.item.name} Subida`, { variant: 'success' });
                }
                catch (err) {
                    enqueueSnackbar(`Error subiendo ${f.item.name}`, { variant: 'error' });
                    console.log(err);
                    if (reply != null) {
                        await srv.delete({ id: reply.id });
                    }
                }
            }        
            
            setLoading(false);
            if (uploads.length == files.length) {
                dispatch(showModal(false));
            }

            files = files.filter(f => uploads.indexOf(f) < 0);
            setFiles(files);

            uploadCount = 0;
            setUploadCount(0);

            dispatch(invalidate(true));
            dispatch(invalidateAlbum(true));

            
            getService(AlbumsService).updateCover({ id: albumId });

        } 
        finally{
            if(wakeLock && wakeLock.released == false){                
                await wakeLock.release();                
                wakeLock = null; 
            }
        }
    }


    async function uploadStream(mediaId: string, file: Blob) {
        let token = localStorage.getItem('token');
        if (!token) {
            window.location.assign("/login");
            throw new Error("Login Required");
        }

        setProgress(-1);

        await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/media/content/stream?id=${mediaId}&contentType=${encodeURIComponent(file.type)}`, file, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                ['Content-Length']: file.size.toString()
            },
            onUploadProgress(progressEvent) {
                setProgress(progressEvent.progress! * 100);
                console.log(progressEvent.progress);
            },
        });
    }



    return (
        <Paper className={styles.root} >
            <AppBar sx={{ position: 'relative' }} elevation={3}>
                <Toolbar variant="dense">
                    <Box sx={{ flexGrow: 1 }} >
                        <Typography >NUEVO CONTENIDO</Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box>
                <Button
                    variant="contained"
                    component="label"
                    fullWidth={true}
                    startIcon={<FileUploadIcon />}>
                    Cargar Contenido
                    <input type="file" hidden onChange={onFileChange} multiple={true} accept="image/png, image/jpeg, image/jpg, video/mp4" disabled={loading} />
                </Button>
                <Box style={{ height: '1em' }}></Box>
                <LoadingButton
                    variant="outlined"
                    loading={loading}
                    disabled={files.length === 0}
                    loadingPosition="start"
                    fullWidth={true}
                    startIcon={<SaveIcon />}
                    onClick={handleSave}>
                    Guardar
                </LoadingButton>

            </Box>
            {files.length > 0 && (
                <Box>
                    <span>Subiendo {uploadCount} de {files.length}</span>
                    {progress >= 0 && (<LinearProgress value={progress} variant="determinate" />)}
                </Box>
            )}
            <Box>
                {files.map((f, idx) => <FileUploadCard key={idx} file={f} onRemove={onRemove} loading={loading} />)}
            </Box>

        </Paper >
    );


}

interface FileUploadProps {
    file: UploadFileState;
    loading: boolean;
    onRemove: (file: UploadFileState) => void;
}

function FileUploadCard({ file, onRemove, loading }: FileUploadProps) {
    let [url, setUrl] = React.useState('');
    let [values, setValues] = React.useState<any>(file.item);
    let videoRef = React.useRef<HTMLVideoElement>(null);

    const setValue = (field: keyof MediaDto, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        (file.item as any)[e.target.name] = e.target.value;
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    React.useEffect(() => {
        setUrl(URL.createObjectURL(file.file));
        return () => {
            URL.revokeObjectURL(url);
            setUrl('');
        }
    }, [file]);

    const onClickRemove = () => {
        onRemove(file);
    }

    let onLoad = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        let video = videoRef.current!;
        video.currentTime = 0.5;
    }

    let onSeeked = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        let canvas = document.createElement('canvas');
        canvas.width = 512;
        canvas.height = 512;

        let ctx = canvas.getContext('2d')!;
        let video = videoRef.current!;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(blob => file.posterBlob = blob, 'image/jpeg', 0.9);
    }

    let contentView = file.item.mediaType == MediaType.image ?
        (<img loading="lazy" src={url} width={300} style={{ objectFit: 'contain', maxHeight: '600px' }} />) :
        (<video ref={videoRef} src={url} width={300} style={{ height: '600px' }} controls onLoadedData={onLoad} onSeeked={onSeeked} />);

    return (
        <Paper elevation={3} className={styles.fileUploadCard}>
            <div className={styles.fileUpladCardImage}>
                {contentView}
            </div>
            <div className={styles.fileUploadProps}>
                <TextField required label="Name" name="name" fullWidth={true} variant="filled" onChange={e => setValue('name', e)} value={values.name} defaultValue={values.name} />
                <TextField label="Description" name="description" fullWidth={true} variant="filled" onChange={e => setValue('description', e)} value={file.item.description} defaultValue={file.item.description} />
                {/* <Button color="error" onClick={onClickRemove}  >Remove</Button> */}
            </div>
            <div className={styles.fileUploadCardDelete} >
                <IconButton onClick={onClickRemove}>
                    <DeleteIcon />
                </IconButton>
            </div>
            {loading && (<div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, zIndex: 2000 }}> </div>)}
        </Paper>
    )
}


