import { Card, CardActionArea, CardMedia, Typography, IconButton, Popover } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MediaDto, MediaType } from "../../api/models";
import styles from "./MediaCard.module.css";
import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { LoadingButton } from "@mui/lab";
import { getService } from "../../app/api";
import { MediaService } from "../../api/services";
import session from "../../app/Session";
import VideoPlayer from "./VideoPlayer";

interface MediaCardProps {
    item: MediaDto;
    token:string|null;
    onView(id: string): void;
    onDeleted?(id: string): void;
}

export default function MediaCard({ item, onView, onDeleted, token }: MediaCardProps) {
    let ref = React.useRef<HTMLDivElement>(null);
    let lodLevel = (item.lods?.length ?? 0) > 1 ? 2 : 0;
    let [actionsStatus, setActionStatus] = React.useState({
        deleting: false,
        downloading: false
    });

    let [url, setUrl] = React.useState(item.mediaType == MediaType.image ?
        `${process.env.REACT_APP_API_URL}/api/v1/media/${item.id!}/content?lodLevel=${lodLevel}` :
        `${process.env.REACT_APP_API_URL}/api/v1/media/${item.id!}/content/poster?`);

    let [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    let [autoPlay, setAutoPlay] = React.useState(false);

    const openActions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteResource = async () => {
        setActionStatus({ ...actionsStatus, deleting: true });
        try {
            const service = getService(MediaService);
            await service.delete({ id: item.id! });
            setAnchorEl(null);
            if (onDeleted) onDeleted(item.id!);
        }
        finally {
            setActionStatus({ ...actionsStatus, deleting: false })
        }
    }

    const downloadResource = async () => {
        setActionStatus({ ...actionsStatus, downloading: true });
        try {
            let response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/media/${item.id!}/content?lodLevel=${0}&jwt=${token}`);
            if(response.status != 200){
                throw new Error('failed to fecth');                                
            }
            //let response = await service.getContent({ id: item.id, lodLevel: 0 });
            let blob = await response.blob();
            if (blob) {
                let objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = objectUrl;
                a.target = '_blank';

                let contentDiposition = response.headers.get('Content-Disposition');
                if (contentDiposition) {
                    let name = contentDiposition.split(';').map(x => x.trim()).find(x => x.startsWith('filename='));
                    if (name) {
                        a.download = name?.split('=')[1];
                    }
                }

                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(objectUrl);

            }
        }
        finally {
            setActionStatus({ ...actionsStatus, downloading: false });
        }
    }

    const onLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        if (item.mediaType == MediaType.image) {
            let lodLevel = (item.lods?.length ?? 0) > 1 ? 1 : 0;
            setUrl(`${process.env.REACT_APP_API_URL}/api/v1/media/${item.id!}/content?lodLevel=${lodLevel}`)
        }
    }

    const handleIntersect =(entries:IntersectionObserverEntry[], observer:IntersectionObserver)=>{
        entries.forEach(e=>{
            if(e.intersectionRatio >= 0.25 && !autoPlay){
                autoPlay = true;
                setAutoPlay(true);
                url = `${process.env.REACT_APP_API_URL}/api/v1/media/${item.id}/content/stream?`;
                setUrl(url)
            }else if(e.intersectionRatio <= 0.01){
                setAutoPlay(false);
                autoPlay = false;
                url = `${process.env.REACT_APP_API_URL}/api/v1/media/${item.id!}/content/poster?`;
                setUrl(url);
            }
        })
    }

    React.useEffect(()=>{
        if(item.mediaType == MediaType.video && ref.current){
            let observer = new IntersectionObserver(handleIntersect, {
                root: null,
                rootMargin: "0px",
                threshold: [0.01, 0.25]
            });
            observer.observe(ref.current);
            return ()=>{        
                observer.disconnect();                
            }
        }
    },[ref.current]);


    return (
        <div key={item.id} className={styles.image_item} ref={ref}>
            <Card elevation={3} className={styles.image_card}>
                <CardActionArea onClick={() => onView(item.id!)}>
                    <div className={styles.image_card_cover} >
                        {item.mediaType == MediaType.video && autoPlay === true && (
                        <>
                        {/* <video  
                            src={`${url}&jwt=${token}`}
                            poster={`${process.env.REACT_APP_API_URL}/api/v1/media/${item.id}/content/poster?jwt=${token}`}                            
                            preload="auto"
                            autoPlay
                            muted
                             /> */}
                            <VideoPlayer item={item} token={token} muted autoplay controls={false} />

                             <div className={styles.image_card_type}>
                                 <VideoFileIcon style={{ fontSize: '2.5rem', color: 'rgb(5 8 125 / 66%)' }} />
                             </div>
                         </>
                        )}
                        { autoPlay === false && (
                        <>
                        <CardMedia
                            component="img"
                            alt={item.name ?? ''}
                            width={370}
                            loading="lazy"
                            onLoad={onLoad}
                            src={`${url}&jwt=${token}`}
                        />
                        {item.mediaType == MediaType.video && (
                            <div className={styles.image_card_type}>
                                <VideoFileIcon style={{ fontSize: '2.5rem', color: 'rgb(5 8 125 / 66%)' }} />
                            </div>
                        )}
                        </>    
                        )}
                      
                    </div>
                </CardActionArea>
                <div className={styles.image_card_content} >
                    <div>
                        <Typography variant="body2" component="div">
                            {item.name}
                        </Typography>
                        {item.description &&
                            (<Typography variant="body2" component="div">
                                {item.description}
                            </Typography>)
                        }
                    </div>
                    <div>
                        <IconButton size="small" style={{ color: 'white' }} onClick={openActions} >
                            <MoreVertIcon></MoreVertIcon>
                        </IconButton>
                    </div>
                </div>
            </Card>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className={styles.action_panel}>
                    {session.canWriteMedia(item) && (
                        <LoadingButton
                            variant="text"
                            loading={actionsStatus.deleting || false}
                            loadingPosition="start"
                            fullWidth={true}
                            startIcon={<DeleteIcon />}
                            color="error"
                            onClick={deleteResource}>
                            ELIMINAR
                        </LoadingButton>
                    )}                    
                    <LoadingButton
                        variant="text"
                        loading={actionsStatus.downloading || false}
                        loadingPosition="start"
                        fullWidth={true}
                        startIcon={<CloudDownloadIcon />}
                        color="primary"
                        onClick={downloadResource}>
                        DESCARGAR
                    </LoadingButton>
                </div>
            </Popover>
        </div>);
}