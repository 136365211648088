import { Box, Button, CircularProgress, Dialog, Modal, Pagination, Paper, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { useAppDispatch } from "../../app/hooks";
import { selectIndex, showImage, showModal, useAppMedia, setOnLoad } from "./mediaSlice";
import styles from "./ListMedia.module.css";
import MediaDetails from "./MediaDetails";
import ImageCard from "./MediaCard";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadImage from "./UploadImage";
import session from "../../app/Session";

export default function ListMedia() {
    const dispatch = useAppDispatch();
    const { state, onPageChange, invalidate } = useAppMedia();
    let [actionsStatus, setActionStatus] = React.useState({
        deleting: false,
        downloading: false,
        backNav: false
    });

    const token = session.getToken();
    const backPageChange = (page: any) => {
        setActionStatus(prev => ({ ...prev, backNav: true }));
        onPageChange(page)
    }

    function onView(imageId: string) {
        let selectedIndex = state.items.findIndex(x => x.id === imageId);
        dispatch(showImage({ show: true, id: imageId, selectedIndex: selectedIndex }));
    }

    React.useEffect(() => {
        if (actionsStatus.backNav) {
            setActionStatus(prev => ({ ...prev, backNav: false }));
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [actionsStatus.backNav]);

    React.useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [])

    const onDeleted = () => {
        dispatch(showImage({ show: false, id: undefined }));
        invalidate(true)
    }

    const onNavigate = (idx: number) => {
        if (idx >= state.items.length && state.page < state.totalPages) {
            dispatch(setOnLoad('show-first'))
            backPageChange(state.page + 1);
        } else if (idx < 0 && state.page > 1) {
            dispatch(setOnLoad('show-last'))
            backPageChange(state.page - 1);
        } else {
            dispatch(selectIndex({ selectedIndex: idx }))
        }
    }

    return (
        <Box className={styles.root}>
            { state.albumId && (
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        {session.canCreateMedia() && (
                            <Button
                                color="primary"
                                onClick={() => dispatch(showModal(true))}
                                startIcon={<CloudUploadIcon />}
                                size="large" >
                                Subir Contenido
                            </Button>
                        )}
                    </Box>                   
                </Toolbar>
            )}

            <div style={{ position: 'relative' }}>
                {state.invalidated === true && (
                    <div className="progress-indicator">
                        <CircularProgress size="5rem" />
                    </div>
                )}
                <div className={state.invalidated === true ? 'content-loading' : undefined}>
                    {state.totalPages > 1 &&
                        (<Paper elevation={3} className="content-pagination">
                            <Pagination count={state.totalPages} variant="outlined" color="primary" page={state.page} onChange={(e, page) => onPageChange(page)} />
                        </Paper>
                        )}

                    <div id="image-list" className={styles.image_grid}>
                        {state.items.map(item => <ImageCard key={item.id} item={item} onView={onView} onDeleted={() => invalidate(true)} token={token} />)}
                    </div>

                    {state.totalPages > 1 &&
                        (<Paper elevation={3} className="content-pagination">
                            <Pagination count={state.totalPages} variant="outlined" color="primary" page={state.page} onChange={(e, page) => backPageChange(page)} />
                        </Paper>
                        )}
                </div>
            </div>

            <Modal
                open={state.showModal}
                onClose={(e) => dispatch(showModal(false))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className={styles.mediaEdit}>
                    <UploadImage />
                </Box>
            </Modal>

            <Dialog
                fullScreen={true}
                open={state.showImage}
                onClose={(e) => dispatch(showImage({ show: false, id: undefined }))}
            >
                {state.showImage &&
                    <MediaDetails
                        index ={ state.selectedIndex! }
                        medias = {state.items}
                        onClose={() => dispatch(showImage({ show: false, id: undefined }))}
                        onDeleted={onDeleted}
                        onNavigate={onNavigate}
                     />
                }
            </Dialog>

        </Box >
    )
}