import React from 'react';
import './App.css';
import Header from './features/header/Header';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppDrawer from './features/drawer/Drawer';
import { Box, CssBaseline, Modal, Typography } from '@mui/material';
import { selectDrawer, open } from './features/drawer/drawerSlice';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { closeFrame, selectAppState, showAlbumModal } from './app/appSlice';
import EditAlbum from './features/album/EditAlbum';
import { Apps } from '@mui/icons-material';
import ListAlbum from './features/album/ListAlbum';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ListMedia from './features/media/ListMedia';
import { ImageViwerWrapper } from './features/media/MediaDetails';
import Login from './features/login/Login';
import { SnackbarProvider } from 'notistack';
import Galery from './features/galery/Galery';
import theme from "./Theme"
import './i18n';
import Profile from './features/profile/profile';

function App() {
  const dispatch = useAppDispatch();
  const appState = useAppSelector(selectAppState);
  const drawerState = useAppSelector(selectDrawer);
  var location = useLocation();
  if (location.pathname != '/login' && !localStorage.getItem('token')) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  
  function handleClose() {
    dispatch(showAlbumModal(false));
  }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Box sx={{ flexGrow: 1, minHeight:'100%' }} >
          <CssBaseline />
          <Header />
          {location.pathname != '/login' && <AppDrawer />}
          <div className="content-main">
            <Routes>
              <Route path="/" element={<Galery />} />
              <Route path="/albums" element={<ListAlbum />} />
              <Route path="/login" element={<Login />} />
              <Route path="/album/:id" element={<ListMedia />} />
              <Route path="/image/:id" element={<ImageViwerWrapper />} />
              <Route path="/profile" element={<Profile/>} />
            </Routes>
            <Modal
              open={appState.showAlbumModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="album-edit">
                <EditAlbum></EditAlbum>
              </Box>
            </Modal>
            {appState.frame && (
              <Modal
                open={appState.frame.open}
                onClose={()=>dispatch(closeFrame(appState.frame!.id))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                  {appState.frame.viewFactory()}
              </Modal>
            )}
          </div>
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );


}

export default App;
