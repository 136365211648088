import { ChangeEventHandler, useMemo, useState } from "react";

type FormValidator<T> = {
  [K in keyof T]: FieldValidator<T[K], T>;
}

export interface FieldValidator<T, M>{
  (value:T, model:M): string|null; 
}

export interface FormModel<T = any> {
  data: T;
  val:FormValidator<T>;
  errors:  {[key:string]: string}
  onChange: ChangeEventHandler<HTMLInputElement> 
}

export const Validators = {
  required: (value?:string|null)=> value && value!="" ?  null: 'required'
};



export function useFormModel<T>(data:T, val:FormValidator<T>){
  let [model, setModel] = useState(data);

  let [form, setForm ] = useState<FormModel<T>>( {
    data:model,
    val,
    errors:{},
    onChange: e=> {
      setModel( (u:any)=> ({...u, [e.target.name]: e.target.value}))
      setForm((prev)=>({...prev, data:{...prev.data, [e.target.name]: e.target.value}})) 
    },    
  });
  
  function validateForm(form:FormModel<any>){
    if(form.val){
      form.errors = {};
      for(let key in form.val){
        let value = form.data[key];
        let err = form.val[key](value ,form.data);      
        if(err) {        
          form.errors[key] = err;
        }
     }
    }

    setForm(prev=>({...prev, errors: form.errors}));
    return Object.keys(form.errors).length == 0
  }

  return {
    form,
    model,
    validateForm
  }
}

