import { LoadingButton } from "@mui/lab";
import { Card, CardActionArea, CardMedia, CardContent, Typography, CardActions, Box, Popover , IconButton} from "@mui/material";
import { AlbumDto } from "../../api/models";
import session from "../../app/Session";
import { invalidate } from "./ListAlbumSlice";
import styles from "./AlbumCart.module.css";
import React from "react";
import { AlbumsService } from "../../api/services";
import { getService } from "../../app/api";
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface AlbumCardProps{
    item:AlbumDto;
    token:string|null;
    onView(id: string): void;
    onDeleted?(id: string): void;
}

export default function AlbumCard({item, onView, onDeleted, token}: AlbumCardProps){
    let ref = React.useRef<HTMLDivElement>(null);
    let [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    let [actionsStatus, setActionStatus] = React.useState({
        deleting: false
    });

    const deleteResource = async () => {
        setActionStatus({ ...actionsStatus, deleting: true });
        try {
            const service = getService(AlbumsService);
            await service.delete({ id: item.id! });
            setAnchorEl(null);
            if (onDeleted) onDeleted(item.id!);
        }
        finally {
            setActionStatus({ ...actionsStatus, deleting: false })
        }
    }

    const openActions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const coverUrl = item.coverId ?
    `${process.env.REACT_APP_API_URL}/api/v1/media/${item.coverId}/content?lodLevel=0&jwt=${token}` :
    '/album256.png'

    return (
    <div>
        <Card elevation={6} className={styles.card}>
            <CardActionArea onClick={() => onView(item.id!)} >
                <CardMedia
                    component="img"                    
                    alt={item.name ?? ''}
                    loading="lazy"
                    src={coverUrl}                    
                />
            </CardActionArea>
            <div className={styles.card_content}>
                <div>
                    <Typography color="primary.contrastText" gutterBottom component="div">
                        {item.name}
                    </Typography>     
                    {item.description &&
                        (<Typography color="primary.contrastText" variant="body2" component="div">
                            {item.description}
                        </Typography>)
                    } 
                </div>        
                <div>
                <IconButton size="small" style={{ color: 'white' }} onClick={openActions} >
                    <MoreVertIcon></MoreVertIcon>
                </IconButton>
                </div>               
            </div>            
        </Card>
        <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className={styles.action_panel}>
                    {session.canWriteAlbum(item) && (
                        <LoadingButton
                            variant="text"
                            loading={actionsStatus.deleting || false}
                            loadingPosition="start"
                            fullWidth={true}
                            startIcon={<DeleteIcon />}
                            color="error"
                            onClick={deleteResource}>
                            ELIMINAR
                        </LoadingButton>
                    )}                                     
                </div>
            </Popover>
    </div>
    );
}