import { ThemeOptions } from '@mui/material/styles';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
      fantasy: true;
    }
  }

export const colors = {
    primary : '#26508e',
    secundary: '#04378f',
    background : '#656662',
    text: '#F1E8E0',
    popup: '#cedfeeed'
}

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: colors.primary,  //'#3f51b5',      
      dark:  '#b6e5fc'      
    },
    secondary: {
      main: colors.secundary, //'#3f8cb5',
      dark: '#ace4fc'
    },
    background:{
      paper: '#F8F4EF'      
    },
    // text:{
    //   primary: '#F1E8E0',      
    //   secondary: '#04063c'
    // }
  },
  components:{
    MuiButton:{
    },
    MuiMenu:{
      styleOverrides:{
        list:{
          backgroundColor: colors.popup
        }
      }
    },
    MuiDrawer:{
        styleOverrides:{
            paper:{
                backgroundColor: colors.popup
            },
            modal:{
                color: '#ffffff'
            }
                       
        }      
    },
    MuiTypography:{
        variants:[
            {
                props: { variant:'fantasy' },
                style: {
                  fontFamily: 'fantasy'                  
                },
              },
        ]
    }    
  }
};

const theme = createTheme(themeOptions);

export default theme;