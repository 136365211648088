import React, { useEffect, useRef, useState } from "react";
import styles from "./SlideListView.module.css"

export interface OnFetchResult<T> {
    items: T[];
    append: boolean;
}

export interface SlideListViewProps<T> {
    items: T[];
    selectedIndex: number;
    slideshow?: boolean;
    render: (item: T, current:boolean , idx: number) => JSX.Element;
    onLoadItems?: (idx: number) => OnFetchResult<T>;
    onSelectedIndexChanged?: (idx: number) => void;
    onPointerMove?: (x:number, y :number) => void;
    onPointerClick?: (x:number, y :number) => void;
    onSlideStart?: () => void;
    OnSlideEnd?: () => void;
}

interface SlideBox {    
    idx: number;
    ref?: HTMLDivElement|null;
    enabled: boolean 
    pos: string;   
}

const transitionOut = "left 500ms ease-in";
const transitionIn = "left 500ms ease-in";

function removeTransition(this: GlobalEventHandlers, ev:TransitionEvent){   
   this.ontransitionend = null;
  (this as HTMLDivElement).style.transition = "";
}

function layout(boxes: SlideBox[], idx: number , transition?:'in'|'out'){
    for (let i = 0; i < boxes.length; i++) {

        const b = boxes[i];                    
        if(b.ref){
            if(transition == 'in'){
                b.ref.style.transition = transitionIn    
            }
            else if(transition == 'out'){
                b.ref.style.transition = transitionOut
            }else{
                b.ref.style.transition = "";            
            }

            b.ref.ontransitionend = removeTransition;

            if(b.idx == idx - 1 ){
                b.ref!.style.left  = '-100%';
            }
            else if(b.idx == idx){
                b.ref!.style.left  = '0px';
            }
            else if(b.idx == idx + 1){
                b.ref!.style.left  = '100%';
            }                
        }
    }
}


export function SlideListView<T>(props: SlideListViewProps<T>) {    
    let x = 0;
    let down = false;   

    const items = props.items;        
    const containerRef = React.useRef<HTMLDivElement|null>(null); 
    const translate = useRef(0);
    const [idx, setIdx] = useState(props.selectedIndex);
    const [initialPos, setInitialPos ] = useState(props.selectedIndex);

    const [boxes, setBoxes] = useState<SlideBox[]>(()=>[
        { idx : props.selectedIndex -1, enabled : (props.selectedIndex - 1) >= 0, ref : null, pos : "-100%" },
        { idx : props.selectedIndex, enabled : true, ref : null, pos : "0px" },
        { idx : props.selectedIndex + 1, enabled : (props.selectedIndex + 1 ) < props.items.length, ref : null, pos : "100%"}
    ]);

    if(initialPos != props.selectedIndex){
        setBoxes([
            { idx : props.selectedIndex -1, enabled : (props.selectedIndex - 1) >= 0, ref : null, pos : "-100%" },
            { idx : props.selectedIndex, enabled : true, ref : null, pos : "0px" },
            { idx : props.selectedIndex + 1, enabled : (props.selectedIndex + 1 ) < props.items.length, ref : null, pos : "100%"}
        ]);
        setIdx(props.selectedIndex);
        setInitialPos(props.selectedIndex);      
    }

    useEffect(()=>{
        if(props.slideshow && items.length > 1){
            let interval = setTimeout(()=>{
                if(idx == (items.length -1)){
                    setIdx(0);
                    setBoxes([
                        { idx : - 1, enabled: false, pos: '-100%', ref:null }, 
                        { idx :0, enabled: true, pos: '0px', ref:null},     
                        { idx : 1 , enabled: items.length > 1, pos: '100%',ref:null },                        
                    ]);
                    if (props.onSelectedIndexChanged){
                        props.onSelectedIndexChanged(0);
                    }
                }else{
                    completeTransition(-1);
                }
            }, 6000);
            return ()=> clearTimeout(interval);
        }
    },[props.slideshow, idx, items])

    function reset() {
        down = false;
        x = 0;
        translate.current = 0;
        layout(boxes, idx, 'out');
        if (props.OnSlideEnd) props.OnSlideEnd();
    }

    function completeTransition(direction: number) {
        
        let prevBox = boxes.find(x=>x.idx == idx - 1);
        let currentBox = boxes.find(x=>x.idx == idx);         
        let nextBox= boxes.find(x=>x.idx == idx + 1);       
        if(!currentBox?.ref)
            return;

        if (direction == 1 && prevBox?.ref) {                                                                  
                prevBox.ref.ontransitionend = ()=>{                    
                    setBoxes([
                        { idx : prevBox!.idx - 1, enabled: (prevBox!.idx - 1) >= 0, pos: '-100%', ref:prevBox?.ref }, 
                        { idx : prevBox!.idx, enabled: true, pos: '0px', ref: currentBox?.ref },     
                        { idx : currentBox!.idx , enabled: true, pos: '100%', ref: nextBox?.ref },                        
                    ])                  
                    setIdx(prevBox!.idx);

                    boxes.forEach(x=> {
                        x.ref!.ontransitionend = null;
                        x.ref!.style.transition = "";
                    })

                    if (props.onSelectedIndexChanged){
                        props.onSelectedIndexChanged(prevBox!.idx);
                    }
                }
                
                prevBox.ref.style.left = '0px';                
                currentBox.ref.style.left = '100%';                
                prevBox.ref.style.transition = transitionIn;                
                currentBox.ref.style.transition = transitionOut;

        } else if (direction == -1 && nextBox?.ref) {       
            nextBox.ref.ontransitionend = ()=>{                    
                    setBoxes([
                        { idx : currentBox!.idx, enabled: true, pos: '-100%', ref:prevBox?.ref  }, 
                        { idx : nextBox!.idx, enabled: true, pos: '0px',  ref: currentBox?.ref },     
                        { idx : nextBox!.idx + 1 , enabled: (nextBox!.idx + 1) < items.length, pos: '100%', ref:nextBox?.ref },                        
                    ]);                                        
                    setIdx(nextBox!.idx);                  

                    boxes.forEach(x=> {
                        x.ref!.ontransitionend = null;
                        x.ref!.style.transition = "";
                    })

                    if (props.onSelectedIndexChanged){
                        props.onSelectedIndexChanged(nextBox!.idx);
                    }
            }
                          
            nextBox.ref.style.left = '0px';
            currentBox.ref.style.left = '-100%';
            nextBox.ref.style.transition = transitionIn;                
            currentBox.ref.style.transition = transitionOut;                
        }else{
            reset();
        }                     
    }

    function updateTranslation(dx: number) {               
        let left = dx;
        for (const b of boxes) {
            let ref = b.ref;
            if (ref) {                
                ref.style.left = `${ref.offsetLeft + left}px`;
            }
        }
    }

    function onPointerUp(x:number, y:number) {
        down = false;
        x = 0;
        if (translate.current != 0) {
            let limit = (containerRef.current?.offsetWidth || 0) * 0.2;
            if (translate.current > limit) {
                completeTransition(1);
            } else if (translate.current < -limit) {
                completeTransition(-1);
            } else {
                reset();
            }

            translate.current = 0;
        } else {
            if (props.onPointerClick) props.onPointerClick(x, y);
        }
    }

    function onPointerMove(clientX:number, clientY: number){
        if (props.onPointerMove) props.onPointerMove(clientX, clientY);
        if (true === down) {  
            let dx = clientX - x;
            if ((idx == 0 && dx > 0) || (idx == (items.length - 1) && dx < 0)) {
                return;
            }    

            translate.current += dx;
            updateTranslation(dx);
            x = clientX;
        }
    }

    function onMouseDown(ev: React.MouseEvent<HTMLDivElement>) {
        ev.preventDefault();
        if (ev.button === 0) {
            down = true;
            translate.current = 0;            
            x = ev.clientX;
            if (props.onSlideStart) props.onSlideStart();
        }
    }

    function onMouseUp(ev: React.MouseEvent<HTMLDivElement>) {
        ev.preventDefault();
        if (ev.button === 0) {
            onPointerUp(ev.clientX, ev.clientY);        
        }
    }

    function onMouseMove(ev: React.MouseEvent<HTMLDivElement>) {
        ev.preventDefault();
        onPointerMove(ev.clientX, ev.clientY);
    }

    function onMouseLeave(ev: React.MouseEvent<HTMLDivElement>) {
        if (down == true) {
            reset();
        }
    }

    function onTouchStart(ev: React.TouchEvent<HTMLDivElement>) {        
        if (ev.touches.length == 1) {
            down = true;
            translate.current = 0;   
            x = ev.touches[0].clientX;
            if (props.onSlideStart) props.onSlideStart();
        }
    }

    function onTouchEnd(ev: React.TouchEvent<HTMLDivElement>) {        
        if (down = true) {
            onPointerUp(ev.changedTouches[0].clientX, ev.changedTouches[0].clientY); 
        }
    }

    function onTouchMove(ev: React.TouchEvent<HTMLDivElement>) {        
        if (ev.touches.length == 1 && true === down) {            
            onPointerMove(ev.touches[0].clientX, ev.touches[0].clientY);
        }
    }

    return (
        <div className={styles.container} ref={containerRef}>       
        {boxes.map(b=>
            (<div ref={ref=> b.ref = ref}                   
                key={b.idx}
                style={{left: b.pos}}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}                    
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
                onTouchMove={onTouchMove}                
                onTouchCancel={onTouchEnd}>
                {b.enabled && props.render(items[b.idx], b.idx === idx, idx)}
            </div>)
            )}        
        </div>
    )
}
