import { Paper, Pagination } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { invalidate } from "../album/ListAlbumSlice";
import { useEffect, useState } from "react";
import { AsyncStatus, getService } from "../../app/api";
import { UserDto } from "../../api/models";
import { useAppDispatch } from "../../app/hooks";
import { UserService } from "../../api/services";
import style from "./TenantInfo.module.css"

export default function TenantInfo(){
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [invalidated, setInvalidated] = useState(true) 
  const [result, setResult] = useState({
    items : [] as UserDto[],    
    totalPages: 0,
    totalItems: 0,
  });  
  const [page, setPage] = useState(1)
  const [pageSize, setpageSize] = useState(20)  
  
  const onPageChange = (ev:any, page: number)=>{
    setPage(page);
    setInvalidated(true);
  }

  useEffect(()=>{
    if(invalidated){
        getService(UserService)
        .listTenantUsers({page, pageSize})
        .then(reply=>{
          setResult({
            items: reply.items || [],
            totalItems: reply.totalItems,
            totalPages: reply.totalPages
          });
        })
        .catch(e=> console.log(e))
        .finally(()=> setInvalidated(false));        
    }
  },[invalidated]);

  return (
    <div style={{ position: 'relative' }}>
    {invalidated === true && (
        <div className="progress-indicator">
            <CircularProgress size="5rem" />
        </div>
    )}
    <div className={invalidated === true ? 'content-loading' : undefined}>
        {result.totalPages > 1 &&
            (<Paper elevation={3} className="content-pagination">
                <Pagination count={result.totalPages} variant="outlined" color="primary" page={page} onChange={onPageChange} />
            </Paper>
            )}

      <div className={style["items-container"]}>
          {/* Card layout for mobile, table layout for larger screens */}
          {result.items.length > 0 ? (
            result.items.map((item) => (
              <div key={item.id} className={style["item-card"]}>
                <div className={style["item-card-header"]}>
                  <h3>{`${item.name} ${item.firstLastName} ${item.secondLastName}`.trimEnd()}</h3>
                </div>
                <div className={style["item-card-body"]}>
                  <p><strong>{t("Email")}:</strong> {item.email}</p>
                  <p><strong>{t("Role")}:</strong> {item.role}</p>                  
                  <p><strong>{t("Create Date")}:</strong> { new Date(item.createdDate as string).toLocaleString() }</p>                  
                  <p><strong>{t("Last Login")}:</strong>{ item.lastLoginDate ? new Date(item.lastLoginDate as string).toLocaleString(): t("Never") }</p>                  
                </div>
              </div>
            ))
          ) : (
            <p>{t("No items found")}</p>
          )}
        </div>

        {result.totalPages > 1 &&
            (<Paper elevation={3} className="content-pagination">
                <Pagination count={result.totalPages} variant="outlined" color="primary" page={page} onChange={onPageChange} />
            </Paper>
            )}
    </div>
</div>
  );
}