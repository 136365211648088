import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import {UserDto} from '../api/models'
import { ReactElement } from 'react';

interface FrameState{
    id: string;
    open:boolean;
    viewFactory: ()=>ReactElement
}

export interface AppState {
    showAlbumModal: boolean;
    user: UserDto|null;
    sectionName?: string;
    frame?:FrameState;
}

const initialState: AppState = {
    showAlbumModal: false,
    user : JSON.parse(window.localStorage.getItem('user') ?? 'null') as UserDto|null
};

export const actionLogout = createAction('app/logout');

export const appSlice = createSlice({
    name: 'app',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        showAlbumModal: (state, action: PayloadAction<boolean>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.showAlbumModal = action.payload;
        },
        setUser(state, action: PayloadAction<UserDto>){
            state.user = action.payload;
        },
        setSectionName(state, action:PayloadAction<string>){
            state.sectionName = action.payload;
        },
        setFrame(state, action:PayloadAction<FrameState>){
            state.frame = action.payload;
        },
        closeFrame(state, action:PayloadAction<string>){
            if(state.frame?.id == action.payload){
                state.frame = undefined;
            }
        }        
    },
    extraReducers(builder) {
        builder.addCase(actionLogout, state=>{
            state.user = null;
        })
    },
});

export const { 
    showAlbumModal, 
    setUser, 
    setSectionName, 
    setFrame,
    closeFrame 
} = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAppState = (state: RootState) => state.app;

export const selectFrameState = (state:RootState) => state.app.frame;
export default appSlice.reducer;
