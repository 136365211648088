import EventEmitter from "events";
import { AlbumDto, MediaDto, UserDto } from "../api/models";

class Session {    
    user: UserDto|null = null;
    events: EventEmitter;
    
    constructor(){
        this.updateUser();
        this.events = new EventEmitter();
    }

    get isAdmin(){
        return this.user?.role === 'admin';
    }

    get isGuest(){
        return this.user?.role === 'guess';
    }

    get isMember(){
        return this.user?.role === 'member'
    }

    get tenantId(){
        return this.user?.tenantId;
    }

    get userId(){
        return this.user?.id;
    }

    login(user: UserDto ){
        this.user = user;
        window.localStorage.setItem('user', JSON.stringify(user));
        this.events.emit('login', user);
    }

    logout(){
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('refresh-token');
        window.localStorage.removeItem('user');
        this.user = null;
        this.events.emit('logout');
    }

    updateUser(){
        this.user = JSON.parse(window.localStorage.getItem('user') || 'null');
        return this.user;
    }

    onUserChanged(user: UserDto ){
        this.user = user;
        window.localStorage.setItem('user', JSON.stringify(user));
        this.events.emit('onChanged', user);
    }

    getToken(){
        return localStorage.getItem('token');
    }

    getRefreshToken(){
        return localStorage.getItem('refresh-token');
    }

    canWriteAlbum(album:AlbumDto){        
        if(this.isGuest) return false;
        if(this.isMember && this.userId != album.userId) return false;
        return true;
    }

    canWriteMedia(media:MediaDto|undefined){
        if(!media) return false;
        if(this.isGuest) return false;
        if(this.isMember && this.userId != media.userId) return false;
        return true;
    }

    canCreateMedia(){
        if(this.isGuest) return false;
        return true;
    }

    canCreateAlbum(){
        if(this.isGuest) return false;
        return true;
    }

}

const session = new Session();

export default session;